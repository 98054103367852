import React, { useEffect } from "react"

import { setLineVisible } from "state/headerState"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ContentError from "components/404/ContentError"

import usePage500 from "hooks/usePage500"

const Error500Page = () => {
  const content = usePage500()
  useEffect(() => {
    setLineVisible(false)
    return () => setLineVisible(true)
  }, [])
  return (
    <Layout noFooter noScroll>
      <SEO
        title={content.metaTitle}
        description={content.metaDescription}
        keywords={content.metaKeywords}
      />
      <ContentError {...content} />
    </Layout>
  )
}

export default Error500Page
