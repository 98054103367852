import { useStaticQuery, graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'

export const usePage500 = () => {
  const { i18n: { language } } = useTranslation()
  const { page500En, page500Ru } = useStaticQuery(
    graphql`
      query {
        page500Ru {
          image
          content
          id
          mainButtonText
          metaTitle
          newsButtonText
          title
          mainButtonLink
          newsButtonLink
        }
        page500En {
          image
          content
          id
          mainButtonText
          metaTitle
          newsButtonText
          title
          mainButtonLink
          newsButtonLink
        }
      }
    `
  )
  if (language === 'en') return page500En
  return page500Ru
}

export default usePage500
